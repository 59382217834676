.section-nav-mobile {
    transform: scaleY(1);
    transition: transform 0.2s ease-in-out, max-height 0.2s ease-in-out,
        transform 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform-origin: top;
    max-height: 1000px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.section-nav-mobile-closed {
    transition: transform 0.2s ease-in-out, max-height 0.2s ease-in-out,
        transform 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform-origin: top;
    height: auto;
    max-height: 0;
    overflow: hidden;
}

.sub-nav-desktop > li > ul {
    overflow: hidden;
    max-height: 0;
    transition: none;
}

.sub-nav-desktop > li.active > ul {
    max-height: 1000px;
    transition: max-height 0.6s;
}
