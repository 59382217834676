.watermark {
    position: relative;
}

.watermark:after {
    content: '';
    display: block;
    pointer-events: none;
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 40px;
    background-image: url('../images/SkiftResearchLogo.png');
    background-position: 0px 30px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.1;
    position: absolute;
    z-index: -1;
}
