@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1 {
        @apply text-4xl sm:text-5xl;
    }
    h2 {
        @apply text-3xl sm:text-4xl;
    }
    h3 {
        @apply text-2xl sm:text-3xl;
    }
    h4 {
        @apply text-xl sm:text-2xl;
    }
    h5 {
        @apply text-lg sm:text-xl;
    }
    p {
        @apply text-base sm:text-lg mb-4;
    }
    nav {
        @apply text-[17px] sm:text-sm;
    }
    ul {
        @apply text-sm sm:text-base;
    }
    .content-wrapper {
        @apply max-w-4xl px-5vw lg:px-0;
    }
    .section-button-wrapper {
        @apply flex items-center px-2 py-2 text-sm border rounded-lg gap-x-1;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'acumin-pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'aria-text-g2', serif;
}

/* table content */

figure {
    width: 100%;
}

table {
    display: table;
    table-layout: auto;
    border-spacing: 2px;
    border-collapse: collapse;
    border-color: inherit;
    text-indent: 0;
    width: 100%;
}

th,
td {
    padding: 5px;
}

table thead th {
    background: rgba(0, 0, 0, 0.1);
}

table thead tr th:first-child {
    text-align: left;
}

table thead tr th {
    text-align: right;
}

table tbody tr td:first-child {
    text-align: left;
    padding-left: 10px;
}

table tbody tr:nth-child(even) {
    text-align: left;
    padding-left: 10px;
    background: rgba(0, 0, 0, 0.05);
}

table tbody tr td {
    text-align: right;
}

table tbody tr strong {
    margin-left: -5px;
    white-space: nowrap;
}

/* section content */
/* 
@media (max-width: 640px) {
    .section-content h1 + h2 {
        margin-top: 0px;
        padding-top: 0px;
    }

    .section-content h2 {
        margin-top: 30px;
    }
}

@media (max-height: 480px) and (max-width: 960px) and (orientation: landscape) {
    .section-content h1 + h2 {
        padding-top: 0px;
        margin-top: 0px;
    }

    .section-content h2 {
        padding-top: 0px;
        margin-top: 30px;
    }
} */
