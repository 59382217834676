.transition-300ms {
    transition: all 0.3s;
}

.hamburger-top-line-animation {
    transform: rotate(46deg);
    transform-origin: left;
}

.hamburger-middle-line-animation {
    width: 0;
    opacity: 0;
}

.hamburger-middle-dot-animation {
    transform: scale(0);
}

.hamburger-bottom-line-animation {
    transform: rotate(-46deg);
    transform-origin: left;
}
